:root{
background-color: #142826;
-webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.App {
  text-align: center;
  font-family: "Bona Nova SC", serif;
  font-weight: 100;
  font-style: normal;
  background-color: #142826;
}

a {
  color: #d5c28f;
  font-size: 1.5rem;
  text-decoration: none;
}

a:hover {
  color: #d8d7b8;
  text-decoration: none;
}

h1 {
  font-size: 1.3rem;
}
h2 {
  font-size: 1.2rem;
}
p {
  font-size: 1.1rem;
  font-family:Arial, Helvetica, sans-serif;
}

.navbar {
  background-image: linear-gradient(to right, #123733, #1f5d57);
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 10px 20px rgb(17, 17, 17);
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 1vmin);
  padding: 10px 20px;
}

.logo {
  height: 50px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(213, 194, 143, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-toggler {
  border-color: #d5c28f ;
}

#socialIcon{
  height: 30px;
  margin: 0 10px;
} 

hr {
  border: #d5c28f 1px solid;
  color: #d5c28f;
  height: 5px;
}

.App-header {
  padding-top: 10px;
  min-height: 50px;
  align-items: center;
  color: #d5c28f;
}

.navbar-light .navbar-nav .nav-link {
  color: #d5c28f;
}

.App-content {
min-height: 95vh;
background-color: #d8d7b8;
display: flex;
flex-direction: column;
justify-content: center;
font-size: calc(10px + 1vmin);
margin-bottom: 60px;
color: white;
}

.Main {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  margin-bottom: 60px;
}

.Main > div {
  text-align: left;
  background-image: linear-gradient(to right, #123733, #1f5d57);
  margin: 20px 30px 20px 0px;
  padding: 30px;
  border-radius: 0 10px 10px 0;
  color: antiquewhite;
  font-size: 1.5rem;
  box-shadow: 10px 10px 20px rgb(17, 17, 17);
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  margin-bottom: 60px;
}

@keyframes appear {
  from{
    opacity: 0;
    filter: blur(5px);
    scale: 0.5;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    filter: blur(0px);
    scale: 1;
    transform: translateX(0);
  }
}

#block .card {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

@media only screen and (max-width: 800px)  {

.About {flex-direction: column;}
.col1 {height: 300px;}
.card {
  color: antiquewhite;
  width: 100%;
  display: flex;
  background-image: linear-gradient(to right, #123733, #1f5d57);
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  font-size: 1.5rem;
  margin: 10px auto;
  padding: 30px;
  box-shadow: 10px 10px 20px rgb(17, 17, 17);
}

}
.animate {
  animation: appear 0.5s ease-in;
  opacity: 1;
}
.About {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.imgBorder {
  border-radius: 100%;
    height: 250px;
    width: 250px;
    margin: 0px 60px 30px 30px;
    border: #d5c28f 2px dashed;
}
.MePic {
  border-radius: 100%;
  width: 250px;
  margin: 30px;
  border: #d5c28f 2px solid;
  box-shadow: 10px 10px 20px rgb(17, 17, 17);
}

.card {
  color: antiquewhite;
  display: flex;
  background-image: linear-gradient(to right, #123733, #1f5d57);
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  font-size: 1.5rem;
  margin: 10px auto;
  padding: 30px;
  box-shadow: 10px 10px 20px rgb(17, 17, 17);
  align-self: center;
}

@media only screen and (min-width: 800px){

.card {
    width: 60%;
    margin: 10px;
    align-self: flex-start;
  }
.card:nth-of-type(even) {
  
align-self: flex-end;
}
}

.Contact {
      color: antiquewhite;
      background-image: linear-gradient(to right, #123733, #1f5d57);
      flex-direction: column;
      border-radius: 10px;
      width: 100%;
      justify-content: center;
      font-size: 1.5rem;
      margin: 20px auto;
      padding: 30px;
      box-shadow: 10px 10px 20px rgb(17, 17, 17);
}

.Contact form {
    display: grid;
    grid-template-rows: repeat(1fr 3fr);
}

.Contact textarea {
  min-height: 200px;
}

.Contact form button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #d5c28f;
}

.enabled {
  text-decoration: none;
}

.wam {
  background-image: linear-gradient(to right, #123733, #1f5d57);
  border-radius: 10px;
  box-shadow: 10px 10px 20px rgb(17, 17, 17);
}

.fb3d {
  height: 600px;
  background-image: linear-gradient(to right, #123733, #1f5d57);
  border-radius: 10px;
  box-shadow: 10px 10px 20px rgb(17, 17, 17);
  justify-content: center;
}

.fb3d > div:not(.gameMenu) {
  border: 2px solid black;
}

.gameMenu {
  margin: auto;
  display: flex;
  position: absolute;
  z-index: 999;
  height: 50px;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
}
.score {
    top: 50%;
    left: 50%;
    font-size: 24px;
    color: black;
}
.game-over {
    font-size: 24px;
    color: red;
    margin-top: 40px;
}
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
  text-decoration:line-through;
}

.App-footer {
  position: fixed;
  display: flex;
  justify-content:space-around;
  bottom: 0px;
  width: 100%;
  padding: 5px 0px;
  background-image: linear-gradient(to right, #123733, #1f5d57);
  min-height: 60px;
  color: #d5c28f;
}
.App-footer p {
  margin-top: 10px;
  margin-bottom: 0px;
}

.wackamole {
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  border: 2px solid black;
}
.Games {
  display: flex;
    flex-direction: column;
    padding-top: 80px;
    margin-bottom: 60px;
 max-width: 480px;
 margin: auto;
 padding-bottom: 80px;
  
}

.d-flex{
  margin: 20px;
  border: 1px solid black;
  padding: 20px;
}


.wrap {
  aspect-ratio: 1;
  overflow: hidden;
}

.wrap img {
  height: 200%;
}
.last-one {
  justify-items: center;
}

.activeSprite img {
  position: relative;
  will-change: transform;
  animation: moveSpritesheet 0.5s steps(6) infinite;
  animation-direction: alternate;
}

#score {
  background-image: url(images/background_score.jpg);
  background-size: 100%;
}
#score h2 {
  margin-top: 50%;
  transform: translateY(-50%);
  color: antiquewhite;
  font-size: 1.5rem;

}
.startBtn {
  margin-top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1rem;
  color: antiquewhite;
}
#controls {
  background-image: url(images/background_start.jpg);
  background-size: 100%;
}

@keyframes moveSpritesheet {
  from {
    transform: translate3d(0px,0,0);
  }
  to {
    transform: translate3d(-100%,0,0);
  }
}

.resetSprite img {
  animation: none;
  transform: translate3d(0px, 0, 0);
}
